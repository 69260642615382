<template>
    <div>
        <ul class="text-margin">
            <div v-for="item in firstPartArray" :key="item.id">
                <li class="text text-margin">{{ $t(item.text) }}</li>
                <div class="screenshot text-margin">
                    <img :src="item.img" alt="" class="step-image">
                </div>
            </div>
            <li class="text" v-for="item in texts" :key="item.textLi">{{ $t(item.textLi) }}
                <p class="text">{{ $t(item.textP) }}</p>
            </li>
            <p class="text text-margin mt-6">{{ $t('faq.tivimate.step9.rememberThat') }}</p>
            <li class="text">{{ $t('faq.tivimate.step9.openTheTivimate') }}</li>
            <div v-for="item in secondPartArray" :key="item.id">
                <li class="text text-margin">{{ $t(item.text) }}</li>
                <div class="screenshot text-margin">
                    <img :src="item.img" alt="" class="step-image">
                </div>
            </div>
        </ul>
    </div>
</template>
<script>
export default {
    computed: {
        firstPartArray() {
            return this.stepsArray.slice(0, 5)
        },
        secondPartArray() {
            return this.stepsArray.slice(5)
        },
    },
    data() {
        return {
            texts: [
                {
                    textLi: 'faq.tivimate.step9.providePayment',
                    textP: 'faq.tivimate.step9.enterTheNecessary'
                },
                {
                    textLi: 'faq.tivimate.step9.completeTheTransaction',
                    textP: 'faq.tivimate.step9.followTheOnscreen'
                },
                {
                    textLi: 'faq.tivimate.step9.confirmation',
                    textP: 'faq.tivimate.step9.onceThePayment'
                },
            ],
            stepsArray: [
                {
                    id: 1,
                    text: 'faq.tivimate.step9.download',
                    img: require('../assets/screen-6.png')
                },
                {
                    id: 2,
                    text: 'faq.tivimate.step9.openTiviMate',
                    img: require('../assets/screen-7.png')
                },
                {
                    id: 3,
                    text: 'faq.tivimate.step9.chooseAccount',
                    img: require('../assets/screen-8.png')
                },
                {
                    id: 4,
                    text: 'faq.tivimate.step9.createTiviMateAcc',
                    img: require('../assets/screen-9.png')
                },
                {
                    id: 5,
                    text: 'faq.tivimate.step9.selectSubscription',
                    img: require('../assets/screen-10.png')
                },
                {
                    id: 6,
                    text: 'faq.tivimate.step9.goToSettings',
                    img: require('../assets/screen-11.png')
                },
                {
                    id: 7,
                    text: 'faq.tivimate.step9.chooseUnlockPremium',
                    img: require('../assets/screen-12.png')
                },
                {
                    id: 8,
                    text: 'faq.tivimate.step9.chooseAccount',
                    img: require('../assets/screen-13.png')
                },
                {
                    id: 9,
                    text: 'faq.tivimate.step9.loginUsing',
                    img: require('../assets/screen-14.png')
                },
                {
                    id: 10,
                    text: 'faq.tivimate.step9.hitNext',
                    img: require('../assets/screen-15.png')
                },
                {
                    id: 11,
                    text: 'faq.tivimate.step9.hitActivate',
                    img: require('../assets/screen-16.png')
                },
                {
                    id: 12,
                    text: 'faq.tivimate.step9.hitOk',
                    img: require('../assets/screen-17.png')
                }
            ]
        }
    },
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
